html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #000;
  font-weight: 500;
}

a {
  color: #60aae4;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

button {
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
}

/* timeline */
.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05);
    margin-left: -12px;
    margin-top: -7px;

}
/* .vertical-timeline-element-icon {
  width: 80px;
  height: 80px;
  left: 50%;
  margin-left: -40px
} */

/* utilities */
.active-navbar {
  color: '#fd5d23';
}

.all-uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

/* input focus */

.form-control:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(96, 170, 228, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(96, 170, 228, 0.5);
}

/* hovering elements */

.listItemHover:hover {
  opacity: 0.9;
}

/* Tagbox in create post page */

.tag-box {
  display: block;
  width: 100%;
  padding: 12px 15px;
  font-size: 12px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.tag-box-pills {
  width: 100%;
  margin-bottom: 5px;
  padding-left: 0;
  margin-top: -10px;
}

.tag-box-pills li {
  text-decoration: none;
  display: inline;
  float: left;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.remove {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  font-weight: 300;
  margin-top: 2.5px;
}

.tag-box input {
  border: none;
  width: 100%;
  font-size: 12px;
}

.autocomplete {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 5px;
  padding: 0;
  padding-left: 15px;
}

/* sweet alert */

.swal2-title {
  font-size: 14px !important;
}

.swal2-content {
  font-size: 12px !important;
  margin-top: 5px !important;
}

.swal2-confirm {
  font-size: 12px !important;
  text-align: center !important;
  color: #fff !important;
  width: 128px !important;
  height: 28px !important;
  background-color: #585858 !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
}

/* responsive modal */

.Modal-modal-0-1-3 {
  padding: 30px 70px !important;
  padding-bottom: 50px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
}

/* Login button colors */

.btn-google {
  background-color: #db3034;
}

.btn-google:hover {
  background-color: #e47365;
}

.btn-google:active {
  background-color: #c23321;
}

.btn-twitter {
  background-color: #1bafe7;
}

.btn-twitter:hover {
  background-color: #21c2ff;
}

.btn-twitter:active {
  background-color: #0087ba;
}

.post {
  margin-bottom: 30px;
}
.post h2 {
  display: block;
  font-weight: bold;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  font-size: 1rem;
}
.post h3 {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.post__field {
  position: relative;
}
.post__control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.post__control .btn {
  margin-left: 10px;
}
.post__input {
  padding: 10px 10px 10px 0;
  border-color: #fff;
  width: 100%;
  cursor: default;
}
.post__input--is-editable {
  border: 1px solid #5cb85c;
  padding-left: 0.75rem;
  cursor: text;
}

/* search bar */
.Select {
  width: 100%;
}

.Select-placeholder {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 40px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select--multi .Select-value {
  background-color: #fff;
  border-radius: 2px;
  border: none;
  color: #585858;
  display: inline-block;
  line-height: 20px;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  vertical-align: top;
  font-size: 12px;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-right: none;
  padding: 1px;
  font-size: 20px;
  color: #585858;
}

.Select--multi .Select-value-icon:hover {
  background: none;
  color: #585858;
  padding: 1px;
  opacity: 0.7;
}

/* quill */
.ql-editor {
  font-family: 'Montserrat';
  font-size: 12px;
}
